.login_container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
}

.login_content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  padding: 0 8px;
}

.login_side_container {
  box-sizing: border-box;
  max-width: 27em;
  background-image: linear-gradient(var(--primary-main), var(--primary-light));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 20;
  overflow: hidden;
  transition: all 0.3s;
}
.login_page {
  left: 0;
}

.signup_page {
  right: 0;
}

.login_inner {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: center;
}

.login_inner h1 {
  box-sizing: border-box;
  max-width: 100%;
  letter-spacing: 0;
  margin: 0 0 16px;
  text-align: center;
  font-size: 2.7rem;
  line-height: 52px;
  margin-bottom: 8px;
}

.login_inner p {
  box-sizing: border-box;
  max-width: 100%;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 16px;
}

.login_form {
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0 auto;
}

.req_form {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0 auto;
}

.fields {
  box-sizing: border-box;
  width: 100%;
  border: none;
  outline: 0;
  padding: 0;
}

.field {
  box-sizing: border-box;
  border-radius: 25px;
  width: auto;
  padding: 0;
  margin: 0;
  outline: 0;
  border: none;
  transition: border 0.2s;
  position: relative;
  margin-bottom: 20px;
}

.field_input {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background: rgba(179, 178, 178, 0.312);
  outline: 0;
  font-size: 0.875rem;
  border-radius: 20px;
  color: var(--color-1);
  padding: 0 16px;
  transition: background 0.3s;
  line-height: 50px;
  overflow: hidden;
  border: 1px solid transparent;
}

.field_input::-webkit-input-placeholder {
  color: var(--color);
}

.field_input::-moz-placeholder {
  color: var(--color);
}

.field_input:-ms-input-placeholder {
  color: var(--color);
}

.field_input::-ms-input-placeholder {
  color: var(--color);
}

.field_input::placeholder {
  color: var(--color);
}

.field_input:focus {
  transition: background 0.3s;
  background: transparent;
  border: 1px solid #e1eae7;
}

.button_login {
  box-sizing: border-box;
  background-color: var(--primary-main);
  width: 235px;
  height: 54px;
  border-radius: 30px;
  outline: 0;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.875rem;
  line-height: 16px;
  text-align: center;
  transition: 0.3s;
  margin: 1rem 0;
}

.button_login:hover {
  background-color: var(--primary-light);
}
.flapper {
  box-sizing: border-box;
  max-width: 26.875rem;
  width: 100%;
}

.side_content_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: 0 0;
}

.text_login_wrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.login_text {
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  color: #fff;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 24px;
}

.swap_btn {
  transition: 0.3s;
  box-sizing: border-box;
  height: 54px;
  min-width: 223px;
  border: none;
  border-radius: 30px;
  color: #1a1e1d;
  font-size: 0.875rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  line-height: 100%;
  box-shadow: 0 12px 24px rgba(34, 51, 49, 0.13);
  background: #fff;
  width: 40%;
}

.swap_btn:hover {
  color: var(--primary-main);
}

.font-smaller {
  font-size: 0.8rem !important;
}

.reg_input {
  width: 20.875rem;
}

.pass_wrapper {
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.pass_wrapper svg {
  transition: all 0.3s;
}

.checkbox {
  font-size: 0.8125rem;
  padding-left: 1.75rem;
  position: relative;
  border-radius: 1px;
  min-height: 20px;
  display: inline-flex;
  align-items: center;
}

.checkbox::before {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  box-sizing: border-box;
  outline: 0;
  border: 1px solid var(--primary-main);
}

.form_icons {
  text-align: center;
  margin-bottom: 1.5rem;
}

.login_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-pack: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 100%;
  margin: 0 1rem 0 0;
  padding: 0;
  width: 2.7rem;
  height: 2.7rem;
  box-sizing: border-box;
}

.login_icon svg {
  fill: #fff;
  height: 20px;
}

.fixed_logo {
  top: 25px;
  left: 25px;
  z-index: 100;
}

.login_outer {
  position: absolute;
  width: 100%;
}

@media (max-width: 768px) {
  .login_outer {
    position: relative !important;
  }
  .login_container {
    position: relative;
    flex-direction: column !important;
  }
  .login_side_container {
    position: relative;
    max-width: 100% !important;
    overflow: visible;
  }
  .login_inner {
    width: 100%;
    padding: 1rem;
  }
  .login .login_inner {
    padding: 15rem 1rem 4rem;
  }
  .login_content {
    padding: 2rem 0;
  }
  .regestration .motion_content {
    padding: 10rem 0 4rem;
  }
  .login .motion_content {
    padding: 4rem;
  }
}
