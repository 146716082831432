* {
  font-family: "Open Sans", sans-serif;
}

a:hover {
  color: inherit;
}

.text_primary {
  color: var(--primary-main);
}
a.text_primary:hover {
  color: var(--primary-dark);
}

.nav-link {
  position: relative;
  color: #333;
}

.active,
a:hover {
  color: var(--primary-dark);
}

.nav-link:hover,
.active {
  color: var(--primary-dark);
}

.search_inner {
  margin: 20px auto;
  width: 500px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
