.section_covered {
  position: absolute !important;
}

.verification .text-sm {
  font-size: 12px;
}
.verification .regular-text {
  font-size: 14px;
  padding-top: 1em;
}
.verification .text-md {
  font-size: 18px;
}
.verification .text-lg {
  font-size: 40px;
  padding-top: 0.5em;
}
.verification .text-color {
  color: #026873;
  font-weight: 800;
}
.verification .content {
  margin: 1.5em auto;
  width: 30%;
  height: 50%;
}
.verification .btn {
  width: 12em;
  background: #54bfa1;
  padding: 0.5em;
  border: none;
  margin: 1em 0;
}
.verification .btn:hover {
  font-weight: 600;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .verification .content {
    width: 80%;
    margin: 1em auto;
    height: 50%;
  }
  .verification .text-lg {
    font-size: 35px;
  }
  .verification .text-md {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) {
  .verification .content {
    width: 50%;
    margin: 1.5em auto;
    height: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .verification .content {
    width: 30%;
    margin: 1.5em auto;
    height: auto;
  }
  .verification .btn {
    width: 14em;
  }
}

.ql-toolbar.ql-snow {
  display: none;
}
